export enum PlayerState {
  INITIALIZING = 'INITIALIZING',
  SPINNING_UP = 'SPINNING_UP',
  PLAYING = 'PLAYING',
  SPINNING_DOWN = 'SPINNING_DOWN',
  PAUSED = 'PAUSED',
  STOPPED = 'STOPPED',
  LOADING = 'LOADING',
  READY = 'READY',
  BUFFERING = 'BUFFERING',
  SCRUBBING = 'SCRUBBING',
  SCRUBBED = 'SCRUBBED',
  SEEKING = 'SEEKING',
  SEEKED = 'SEEKED',
  COMPLETED = 'COMPLETED',
}
